/**
 * This file contains many different ajax calls which use parameters to ask for specific data, and draws it to a map.
 * This can be zones, points, lines, etc.
 *
 * The main map uses showAllMarkersOnMapInRange(), while most others (such as those on the site dash and zoning pages) use specific calls.
 */

import Config from "../config/constants";
import MapConfig from '../config/map_config';
import { siteId } from './site_management';
import {userIds, eventTypeIds, geofenceIds, machineIds} from './map';
import mapboxgl from 'mapbox-gl';
import moment from 'moment';
import {createResourceSelects, deleteZone, removeResourceRows, savePopoutForm} from './planning_map'
import {mapObject} from "./planning_map";
import * as turf from "@turf/turf";
import 'animate.css'
import Swal from "sweetalert2";
import {closeMenu, openMenu} from "./map_filter_toggle";

const locationDataAjaxCall = (map, siteId, userIds, isTunnel) => {
  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/location_data?apikey=${Config.apiKey}&site_id=${siteId}&user_ids=${userIds}&event_type=${eventTypeIds}&is_tunnel=${isTunnel}`,
    dataType: "json",
    success: data => {
      if (data !== undefined) {
        plotLocationMarkers(map, data.location_data, false, eventTypeIds);
      }
    },
    error: data => {
      console.log("Error trying to retrieve location data. Please refresh the page and try again.");
    }
  });
}

const showAllMarkersOnMapInRange = (map, user_ids, fence_id, machine_zone_ids, date_param, precision_level, event_types) => {
  $(document.body).css({'cursor' : 'wait'});
  $('.progress-bar').show();
  $(".data-loading-box").show();
  let event_ids;
  if (event_types == null || event_types == ""){
    event_ids = eventTypeIds;
  }else{
    event_ids = event_types;
  }
  const tunnel = $("#is_tunnel").val();
  let isTunnel = false
  if (tunnel) {
    isTunnel = true
  }

  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/all_markers_for_map_with_date?apikey=${Config.apiKey}&site_id=${siteId}&user_ids=${user_ids}&machine_ids=${machineIds}&date=${date_param}&event_type=${event_ids}&precision_level=${precision_level}&zone_ids=${fence_id}&machine_zone_ids=${machine_zone_ids}&is_tunnel=${isTunnel}`,
    dataType: "json",
    success: async data => {
      if (data !== undefined) {
        console.log(data)
        if (data.use_slider == true) {
          plotLocationForSlider(map, data);
          // Set the starting date-time for the slider
          document.getElementById('date_param').value = data.mid_time
        } else {
          await plotLocationMarkers(map, data.location_data, true, event_ids);
        }
        $('.progress-bar').hide();
        return true;
      }
    }
  })
}

function get_event_name(event_type_id, item){
  if(event_type_id == EVENT_IDS.AT_RISK_START_ID){
    return "At Risk"
  }else if(event_type_id == EVENT_IDS.LOW_SIGNAL_ID){
    return "Low Signal"
  }else if(event_type_id == EVENT_IDS.BATTERY_LEVEL_ID){
    return "Low Battery"
  }else if(event_type_id == EVENT_IDS.BUMP_START_ID) {
    return "Bump"
  }else if(event_type_id == EVENT_IDS.ACTIVE_SOS_ID){
    return "SOS"
  }else if(event_type_id == EVENT_IDS.TIMEOUT_START_ID){
    return "Timeout"
  }else if(event_type_id == null || event_type_id == EVENT_IDS.LIVE_LOCATION_ID) {
    return "Location"
  }else if(event_type_id == EVENT_IDS.MACHINE_TAG_LOCATION_ID) {
    return "Machine Location"
  }else if(event_type_id == EVENT_IDS.TAG_HAZARD_ZONE_ENTRY_ID || event_type_id == EVENT_IDS.MACHINE_TAG_ZONE_ENTRY_ID){
    if(item.is_machine){
      return "Machine Zone Interaction"
    }else{
      return "Personnel Zone Interaction"
    }
  }else if (event_type_id == EVENT_IDS.MACHINE_SPEEDING_START_ID) {
    return "Machine Speeding"
  }else if (event_type_id == EVENT_IDS.MACHINE_TAG_IDLE_START) {
    return "Machine Idle"
  }else{
    return "Location"
  }
}

function get_event_color(event_type_id, item, is_personnel){
  if(event_type_id == EVENT_IDS.ACTIVE_SOS_ID){
    return "#FF0000";
  }

  if(event_type_id == EVENT_IDS.AT_RISK_START_ID){
    return "#3e3e3e";
  }

  if(event_type_id == EVENT_IDS.LOW_SIGNAL_ID){
    return "#47a3da";
  }

  if(event_type_id == EVENT_IDS.BATTERY_LEVEL_ID){
    return "#8811ff";
  }

  if(event_type_id == EVENT_IDS.BUMP_START_ID) {
    return "#ff00fb";
  }

  if(event_type_id == EVENT_IDS.TIMEOUT_START_ID){
    return "#ffe500";
  }

  if(event_type_id == null || event_type_id == EVENT_IDS.LIVE_LOCATION_ID){
    if (item && item.during_zone_interaction) {
      return "#001751"
    }

    return "#48c774";
  }

  if(event_type_id == EVENT_IDS.MACHINE_TAG_LOCATION_ID){
    return "#48c774";
  }

  if(event_type_id == EVENT_IDS.TAG_HAZARD_ZONE_ENTRY_ID || event_type_id == EVENT_IDS.MACHINE_TAG_ZONE_ENTRY_ID){
    return "#001751";
  }

  if (event_type_id == EVENT_IDS.MACHINE_SPEEDING_START_ID) {
    return "#ff3f00";
  }

  if (event_type_id == EVENT_IDS.MACHINE_TAG_IDLE_START) {
    return "#00ffe1";
  }

  if(event_type_id == EVENT_IDS.PEOPLE_PLANT_ENTRY_ID || event_type_id == EVENT_IDS.PEOPLE_PLANT_EXIT_ID ||
            event_type_id == EVENT_IDS.PLANT_PLANT_ENTRY_ID || event_type_id == EVENT_IDS.PLANT_PLANT_EXIT_ID){
    if(is_personnel){
      return "#FFA500";
    }else{
      return "#001751";
    }
  }

  if (item && item.during_zone_interaction) {
    return "#001751"
  }

  return "#48c774";
}

const plotLocationMarkers = (map, data, is_range, event_ids) => {
  const geojson = {
    type: 'FeatureCollection',
    features: data,
  };

  if (is_range) {
    $(".location-marker").remove();

    addLayerToMap(map, 'location-markers', data, event_ids, true, null)

    $(".data-loading-box").hide();
  } else {
    // Remove the layers and sources upon refresh, so if the locations update we render them in the updated location
    $(".live-location-marker").remove();
    $(".active-sos-marker").remove();
    $(".live-location-during-zone-interaction-marker").remove();

    for (const marker of data) {
      var title = marker.properties.title + '<br>' + marker.properties.description
      title = title + '<br>' + marker.properties.timestamp;
      // Create a DOM element for each marker.
      const el = document.createElement('div');
      if (marker.properties.event_type_id == EVENT_IDS.ACTIVE_SOS_ID) {
        el.className = 'active-sos-marker';
      } else if (marker.properties.during_zone_interaction == true) {
        el.className = 'live-location-during-zone-interaction-marker';
      } else {
        el.className = 'live-location-marker';
      }

      el.style.textAlign = "center";
      el.style.width = `30px`;
      el.style.height = `30px`;
      let popup = new mapboxgl.Popup({offset: 10, anchor: 'bottom'}).setHTML(title);
      // Add markers to the map.
      let new_marker = new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates.slice())
          .setPopup(popup)
          .addTo(map);

      el.addEventListener('click', function(e) {
        new_marker.togglePopup()

        e.stopPropagation();
      })
    }
    $(document.body).css({'cursor' : 'default'});
    $(".data-loading-box").hide();
  }
}

const plotLocationForSlider = (map, data) => {
  let locationPoints = []
  let sosPoints = []
  data.location_data.forEach((item, index) => {
    let location = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [item.longitude, item.latitude]
      },
      properties: {
        title: item.sos_active ? 'SOS' : 'Location',
        description: `User #${item.worker_id}`,
        timestamp: item.converted_timestamp,
        minute: moment(item.timestamp).minutes(),
        sosActive: item.sos_active,
        marker: item.sos_active ? 'sos-marker' : 'location-marker',
        event_color: get_event_color(item.event_type_id, item, true)
      }
    };
    if (item.sos_active){
      sosPoints.push(location)
    }else{
      locationPoints.push(location);
    };
  });

  const geojson = {
    type: 'FeatureCollection',
    features: locationPoints
  };

  const sosJson = {
    type: 'FeatureCollection',
    features: sosPoints
  }

  addLayerToMap(map, 'locations', geojson, null, false)
  addLayerToMap(map, 'sos', sosJson, null, false)

  map.setFilter('locations', ['==', ['number', ['get', 'minute']], moment(data.mid_time).minutes()]);
  map.setFilter('sos', ['==', ['number', ['get', 'minute']], moment(data.mid_time).minutes()]);
};

const imageDataAjaxCall = async (map, geofence_id, date_param, precision_level, should_show_location, is_preview, should_hide_initially, should_show_menu, zone_type, site_id, sector_id, is_planning, show_zones, is_tunnel) => {
  // If a site id has been passed in, use that, if it hasn't and a sector id has been passed, then we want to use the sector id
  // otherwise just use the imported siteId
  let site_id_to_send;
  if(site_id){
    site_id_to_send = site_id;
  }else if(sector_id){
    site_id_to_send = null;
  }else{
    site_id_to_send = siteId;
  }
  await $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/image_data?apikey=${Config.apiKey}&site_id=${site_id_to_send}&sector_id=${sector_id}&is_tunnel=${is_tunnel}`,
    dataType: "json",
    success: async data => {
      data.image_data.forEach((item, index) => {
        // for each image returned, add it to the map depending on which type of file it is
        if (item.file_type === "vector") {
          // kml files do not seem to use the the whole name in the source layer it generates on mapbox, so strip it back
          let sourceLayer = item.file_name.replace(item.company + "_", "");
          addImageToMap(map, item, "vector", "line", `${item.file_name}_upload`, item.line_color, item.line_width);
        } else if (item.file_type === "geojson") {
          addImageToMap(map, item, "vector", "line", `${item.file_name}_upload`, item.line_color, item.line_width)
        } else {
          addImageToMap(map, item, "raster", "raster", `${item.file_name}`);
        }
      });
    },
    error: data => {
      console.log("Error trying to retrieve image data. Please refresh the page and try again.");
    }
  })
  return true;
}

const perimeterAjaxCall = (map, perimeterId, siteId, isPerimeterOnMap, isPreview) => {
  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}geofences/perimeter_data?apikey=${Config.apiKey}&perimeter_id=${perimeterId}&site_id=${siteId}`,
    dataType: 'json',
    success: data => {
      if (data !== undefined){
        addGeofenceToMap(map, data.perimeter_data, isPreview, isPerimeterOnMap, false);
      }
    },
    error: data => {
      console.log("Error trying to retrieve perimeter data. Please try again.")
    }
  })
}

const machineZoneAjaxCall = async(map, date_param, zone_ids, is_machine_map, is_main_map, is_preview, is_type_search, type, machine_ids) => {
  await $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/machine_zone_preview_data?apikey=${Config.apiKey}&date_param=${date_param}&zone_ids=${zone_ids}&site_id=${siteId}&is_type_search=${is_type_search}&type=${type}&machine_ids=${machine_ids}`,
    dataType: "json",
    success: data => {
      if (data.geofence_data != null && data.geofence_data != undefined) {
        if (is_machine_map) {
          if ((data.specific_access && data.specific_access == true) || data.no_zones) {
            if (is_type_search && is_type_search == 'true') {
              $('#assign-machines-top-banner-header').text(`Viewing Zone Access For ${type}`);
            }else {
              $('#assign-machines-top-banner-header').text('Viewing Zone Access For The Selected Machines');
            }
          } else {
            $('#assign-machines-top-banner-header').text('Conflicting Access Detected For Selected Machines, Showing Default Zone Access');
          }
          $('.assign-machines-top-banner').center_horizontal();
          $('.assign-machines-top-banner').show();
        }

        let geofence_collection = [];
        // foreach geofence create a polygon object to use as the mapbox source data
        data.geofence_data.forEach((item, index) => {
          if (item.geofence_array) {
            let fillColor = getGeofenceColor(item, false, true);
            let outlineColor = getGeofenceColor(item, true, true);
            let polygon = {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [JSON.parse(item.geofence_array)]
              },
              properties: {
                id: item.zone_id,
                title: item.name,
                zone_name: item.name,
                fill_color: fillColor,
                outline_color: outlineColor,
              }
            };
            geofence_collection.push(polygon);
          }
        });

        // Create a geojson using the data created above and add it to the map
        const geojson = {
          type: 'FeatureCollection',
          features: geofence_collection
        };

        map.addSource('machine_zone_source', {
          type: 'geojson',
          data: geojson
        })

        map.addLayer({
          'id': 'machine_zones',
          'type': 'fill',
          'source': 'machine_zone_source',
          'paint': {
            'fill-outline-color': ['get', 'outline_color'],
            'fill-color': ['get', 'fill_color'],
            'fill-opacity': 0.3
          }
        })

        map.addLayer({
          'id': 'machine_zones_outlines',
          'type': 'line',
          'source': 'machine_zone_source',
          'layout': {},
          'paint': {
            'line-color': ['get', 'outline_color'],
            "line-width": 2
          }
        });

        map.on('click', 'machine_zones', function (e) {
          if (map.getLayer('polygons')) {
            map.setFilter('polygons', null);
            map.setLayoutProperty(
                'polygons',
                'visibility',
                'visible'
            );
          }

          if (is_overlapping_layer(map, e)) {
            return
          }

          let item = e.features[0];
          $.ajax({
            type: 'GET',
            url: `${Config.apiUrl}live/machine_zone_further_details?apikey=${Config.apiKey}&machine_zone_id=${item.properties.id}&site_id=${siteId}&is_machines=${true}`,
            dataType: "json",
            success: data => {
              let zone = data.machine_zone_data;
              if (is_main_map) {
                populateMapPopoutScreen(zone, true);
                flyToZone(zone, map)
              }

              if (!is_preview) {
                if ((!is_machine_map) || zone.access_level >= 3) {
                  flyToZone(zone, map)
                  populatePopoutScreen(zone, false);
                  $("#select-all-exceptions").prop('checked', false);
                  map.setLayoutProperty(
                      'machine_zones',
                      'visibility',
                      'visible'
                  );
                  map.setFilter('machine_zones', ['==', ['get', 'zone_name'], item.properties.zone_name]);
                }

                let popout_menu = document.getElementById('popout-menu');
                document.getElementById('popout-close').onclick = function () {
                  closeMenu('popout-menu');

                  map.setFilter('machine_zones', null);
                  map.setLayoutProperty(
                      'machine_zones',
                      'visibility',
                      'visible'
                  );
                }
              }
            }
          })
        });
      }
    },
    error: data => {
      console.log("Error trying to retrieve machine zone. Please refresh the page and try again.");
    }
  });
  return true;
}

jQuery.fn.center = function () {
  this.css("position","absolute");
  this.css("top", Math.max(0, (($(window).height() - $(this).outerHeight()) / 2) +
      $(window).scrollTop()) + "px");
  this.css("left", Math.max(0, (($(window).width() - $(this).outerWidth()) / 2) +
      $(window).scrollLeft()) + "px");
  return this;
}

jQuery.fn.center_horizontal = function () {
  this.css("position","absolute");
  this.css("left", Math.max(0, (($(window).width() - $(this).outerWidth()) / 2) +
      $(window).scrollLeft()) + "px");
  return this;
}

const geofenceAjaxCall = async (map, geofence_id, date_param, precision_level, should_show_location, is_preview, should_hide_initially, should_show_menu, zone_type, is_planning, selected_zone_id, is_personnel_map, is_machines_map, worker_ids, is_role_search, role, competencies) => {
  let fenceId = ''
  if (geofence_id != null && geofence_id != '') {
    fenceId = geofence_id;
  } else {
    fenceId = geofenceIds;
  }

  let selected_zone_name = ''

  await $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/geofence_preview_data?apikey=${Config.apiKey}&geofence_id=${fenceId}&site_id=${siteId}&zone_type=${zone_type}&date_param=${date_param}&is_planning=${is_planning}&worker_ids=${worker_ids}&is_role_search=${is_role_search}&role=${role}&competencies=${competencies}`,
    dataType: "json",
    success: data => {
      if (data.geofence_data != null && data.geofence_data != undefined) {
        if (is_personnel_map) {
          if ((data.specific_access && data.specific_access == true) || data.no_zones) {
            if (is_role_search && is_role_search == 'true') {
              let text = ''
              if (role != '') {
                text += `Viewing Zone Access For ${role}`
                if (competencies && competencies.split(", ").length > 0){
                  text += ` With ${competencies.split(", ").join(" and ")}`
                }
              } else {
                text += `Viewing Zone Access For ${competencies.split(", ").join(" and ")}`
              }
              $('#assign-personnel-top-banner-header').text(text);
            }else {
              $('#assign-personnel-top-banner-header').text('Viewing Zone Access For The Selected Personnel');
            }
          } else {
            $('#assign-personnel-top-banner-header').text('Conflicting Access Detected For Selected Personnel, Showing Default Zone Access');
          }
          $('.assign-personnel-top-banner').center_horizontal();
          $('.assign-personnel-top-banner').show();
        }

        let geofence_collection = [];
        // foreach geofence create a polygon object to use as the mapbox source data
        data.geofence_data.forEach((item, index) => {
          if (item.geofence_array) {
            let fillColor = getGeofenceColor(item, false);
            let outlineColor = getGeofenceColor(item, true);
            let polygon = {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [JSON.parse(item.geofence_array)]
              },
              properties: {
                id: item.zone_id,
                title: item.name,
                zone_name: item.name,
                fill_color: fillColor,
                outline_color: outlineColor,
              }
            };
            geofence_collection.push(polygon);

            if (item.zone_id == selected_zone_id) {
              selected_zone_name = item.name;
            }
          }
        });

        // Create a geojson using the data created above and add it to the map
        const geojson = {
          type: 'FeatureCollection',
          features: geofence_collection
        };

        map.addSource('polygon_source', {
          type: 'geojson',
          data: geojson
        })

        map.addLayer({
          'id': 'polygons',
          'type': 'fill',
          'source': 'polygon_source',
          'paint': {
            'fill-outline-color': ['get', 'outline_color'],
            'fill-color': ['get', 'fill_color'],
            'fill-opacity': 0.3
          }
        })

        map.addLayer({
          'id': 'polygon_outlines',
          'type': 'line',
          'source': 'polygon_source',
          'layout': {},
          'paint': {
            'line-color': ['get', 'outline_color'],
            'line-width': 1
          }
        });

        map.on('click', 'polygons', function (e) {
          if (map.getLayer('machine_zones')) {
            map.setFilter('machine_zones', null);
            map.setLayoutProperty(
                'machine_zones',
                'visibility',
                'visible'
            );
          }

          if (is_overlapping_layer(map, e)) {
            return
          }

          let item = e.features[0];
          $.ajax({
            type: 'GET',
            url: `${Config.apiUrl}live/geofence_further_details?apikey=${Config.apiKey}&geofence_id=${item.properties.id}&site_id=${siteId}&is_machines=${is_machines_map}`,
            dataType: "json",
            success: data => {
              let zone = data.geofence_data;
              if (should_show_menu && !is_personnel_map && !is_machines_map) {
                populateMapPopoutScreen(zone, false);
                flyToZone(zone, map)
              }

              if ((zone.access_level >= 3) && (is_personnel_map || is_machines_map)) {
                $("#select-all-exceptions").prop('checked', false);
                flyToZone(zone, map)
                  populatePopoutScreen(zone, is_personnel_map);
              }

              if (!is_preview) {
                if ((!is_personnel_map && !is_machines_map) || zone.access_level >= 3) {
                  map.setLayoutProperty(
                      'polygons',
                      'visibility',
                      'visible'
                  );
                  map.setFilter('polygons', ['==', ['get', 'zone_name'], item.properties.zone_name]);
                }

                let popout_menu = document.getElementById('popout-menu');
                document.getElementById('popout-close').onclick = function () {
                  closeMenu('popout-menu');

                  map.setFilter('polygons', null);
                  map.setLayoutProperty(
                      'polygons',
                      'visibility',
                      'visible'
                  );
                }
              }
            }
          })
        });

        if (should_hide_initially) {
          map.setLayoutProperty(
              'polygons',
              'visibility',
              'none'
          );
        }
      }
    },
    error: data => {
      console.log("Error trying to save geofence. Please refresh the page and try again.");
    }
  });
  return true;
}

// Check if where we are clicking is a location marker on top of a geofence
// if so, we don't show the zone info menu
function is_overlapping_layer(map, e){
  let f = map.queryRenderedFeatures(e.point);
  for (let i = 0; i < f.length; i++){
    if (f[i].layer.type == 'circle'){
      return true;
    }
  }
  return false;
}

function flyToZone(zone, map) {
  let latitude = zone.coordinates.split(", ")[0];
  let longitude = zone.coordinates.split(", ")[1];
  map.flyTo({
    center: [
      latitude,
      longitude
    ],
    zoom: 17,
    essential: true // this animation is considered essential with respect to prefers-reduced-motion
  });
}

function populatePopoutScreen(zone, is_personnel) {
  let popoutMenu = document.getElementById('popout-menu');
  openMenu('popout-menu');
  // Set the properties of the zone
  document.getElementById('popout-zone-name').innerText = zone.zone_name;

  createPopoutExceptionsList(zone, zone.exceptions, is_personnel);

  if (is_personnel){
    let add_personnel_to_zone_button = $("#personnel-popout-add-personnel");
    add_personnel_to_zone_button.data('geofence_id', zone.zone_id)
  } else {
    let add_personnel_to_zone_button = $("#machines-popout-add-machines");
    add_personnel_to_zone_button.data('geofence_id', zone.zone_id)
  }
}

function populateMapPopoutScreen(zone, is_machine_zone) {
  let save_button = document.getElementById('planning-popout-save');
  save_button.setAttribute('is_machine_zone', is_machine_zone);
  save_button.setAttribute('zone_id', zone.zone_id);
  document.getElementById('popout-date-from').disabled = false;
  document.getElementById('popout-date-to').disabled = false;
  save_button.classList.remove("is-disabled");
  document.getElementById('planning-popout-delete').classList.remove("is-disabled");
  document.getElementById('default_access_level').disabled = false;

  let popoutMenu = document.getElementById('popout-menu');
  openMenu('popout-menu');
  // Set the properties of the zone
  document.getElementById('popout-zone-name').innerText = zone.zone_name;
  document.getElementById('popout-date-from').value = moment(zone.start_date).format('YYYY-MM-DD');
  document.getElementById('popout-date-to').value = moment(zone.end_date).format('YYYY-MM-DD');
  // document.getElementById('dependent').value = item.properties.depends_on;
  document.getElementById('default_access_level').value = zone.planning_access_level;

  if (is_machine_zone) {
    document.getElementById('default_access_column').classList.add('popout-half-column');
    $("#speed_limit_container").attr('style','display:inline-block !important');
    $("#popout-speed-limit").val(zone.speed_limit);
    $("#machine-icon-div").show();
    $("#personnel-icon-div").hide();
  } else {
    document.getElementById('default_access_column').classList.remove('popout-half-column');
    $("#speed_limit_container").attr('style','display:none !important');
    $("#machine-icon-div").hide();
    $("#personnel-icon-div").show();
  }

  createPopoutExceptionsList(zone, zone.exceptions, !is_machine_zone);

  // Populate the change logs in the popout menu
  let changeLogTable = document.getElementById('popout-change-log-table');
  let newTbody = document.createElement('tbody');
  newTbody.id = 'popout-change-log-table';
  if (zone.change_logs) {
    let changeLogs = zone.change_logs;
    for (let i = 0; i < changeLogs.length; i++) {
      let tr = document.createElement('tr');
      let previousStartDateTd = document.createElement('td');
      previousStartDateTd.innerText = changeLogs[i].previous_start_date;
      let previousEndDateTd = document.createElement('td');
      previousEndDateTd.innerText = changeLogs[i].previous_end_date;
      let newStartDateTd = document.createElement('td');
      newStartDateTd.innerText = changeLogs[i].new_start_date;
      let newEndDateTd = document.createElement('td');
      newEndDateTd.innerText = changeLogs[i].new_end_date;
      let reasonTd = document.createElement('td');
      reasonTd.innerText = changeLogs[i].reason;
      let timestampTd = document.createElement('td');
      timestampTd.innerText = changeLogs[i].timestamp;
      tr.appendChild(previousStartDateTd);
      tr.appendChild(previousEndDateTd);
      tr.appendChild(newStartDateTd);
      tr.appendChild(newEndDateTd);
      tr.appendChild(reasonTd);
      tr.appendChild(timestampTd);
      newTbody.appendChild(tr);
    }
  }
  changeLogTable.parentNode.replaceChild(newTbody, changeLogTable);
  document.getElementById('popout-close').onclick = function () {
    popoutMenu.classList.remove('animate__slideInRight');
    popoutMenu.classList.add('animate__animated', 'animate__slideOutRight',  'animate__faster');
    popoutMenu.setAttribute('style', 'visibility:hidden !important');
  }
  save_button.onclick = function (e) {
    savePopoutForm(e, siteId);
  }
  document.getElementById('planning-popout-delete').onclick = function () {
    Swal.fire({
      title: 'Delete Zone',
      text: "Are you sure you want to delete this zone?",
      icon: 'warning',
      showCancelButton: true,
    }).then((result) => {
      if(result.isConfirmed){
        deleteZone(siteId);
      }else{
        Swal.fire("Zone has not been deleted.")
      }
    })
  }

  // Dont enable configuration for ESRI zones
  if(zone.api_id != null && zone.api_id != 'null'){
    document.getElementById('popout-date-from').disabled = true;
    document.getElementById('popout-date-to').disabled = true;
    document.getElementById('planning-popout-save').classList.add("is-disabled");
    document.getElementById('planning-popout-delete').classList.add("is-disabled");
    document.getElementById('default_access_level').disabled = true;
  }
}

function createPopoutExceptionsList(zone, exceptions_list, is_personnel) {
  // Set the exceptions list
  let exceptionsContainer = document.getElementById('exceptions-container');
  let exceptionsList = document.getElementById('exceptions-list-body');
  exceptionsList.innerHTML = ``;
  if(zone.access_level > 1 && exceptions_list.length > 0) {
    $("#no-exceptions-box").hide();

    exceptionsContainer.style.display = "block";
    for (let i = 0; i < exceptions_list.length; i++) {
      createExceptionRow(String(exceptions_list[i]), zone.zone_id, zone.zone_name, is_personnel, exceptionsList);
    }
  }else if (zone.access_level > 1) {
    exceptionsContainer.style.display = "none";
    $("#no-exceptions-box").show();
  }else{
    $("#no-exceptions-box").hide();
    exceptionsContainer.style.display = "none";
  }
}

function createExceptionRow(exception_name, zone_id, zone_name, is_personnel, exceptionsList) {
  let already_exists = false;
  $(".personnel-description-td").each(function(i, obj) {
    if ($.trim(obj.innerText) === $.trim(exception_name)) {
      already_exists = true;
      return false;
    }
  })

  if (already_exists) {
    return
  }

  let tr = document.createElement('tr');
  tr.classList.add('has-margin-top-10');
  tr.classList.add(`tr-${exception_name.replace(/\s/g,'')}`)
  let checkbox_td = document.createElement('td');
  let checkbox_input = document.createElement('input');
  checkbox_input.classList.add('checkbox');
  checkbox_input.classList.add('exception-checkbox')
  checkbox_input.type = 'checkbox';
  checkbox_input.setAttribute('selected_id', exception_name);
  checkbox_td.appendChild(checkbox_input);
  let description_td = document.createElement('td');
  description_td.innerText = exception_name;
  description_td.classList.add('personnel-description-td');
  description_td.classList.add('is-6');
  let access_td = document.createElement('td');
  access_td.classList.add('personnel-access-td');
  access_td.classList.add('is-6');
  access_td.innerText = 'Allowed'; // Currently the only exceptions are people allowed in not allowed zones, but this may change in the future
  let remove_td = document.createElement('td');
  let remove_div = document.createElement('div');
  remove_div.innerHTML = '<div class="personnel-popout-delete button fas fa-trash"></div>';
  remove_td.classList.add('personnel-remove-td');
  remove_div.setAttribute('selected_id', exception_name);
  remove_td.appendChild(remove_div);

  $("#select-all-exceptions").on("click", function() {
    if($(this).is(':checked')) {
      $('.exception-checkbox').prop('checked', true);
    }else {
      $('.exception-checkbox').prop('checked', false);
    }
  });

  $("#remove-selected-exceptions").on("click", function () {
    let exceptions = new Set();
    $('.exception-checkbox').each(function() {
      if($(this).is(':checked')) {
        exceptions.add($(this).attr('selected_id'))
      }
    })
    if(exceptions.size > 0) {
      let exceptions_string = Array.from(exceptions).join(",")
      Swal.fire({
        title: `Remove ${is_personnel ? 'personnel' : 'machine'} from the zone`,
        text: `Are you sure you want to remove all the selected exceptions from ${zone_name}?`,
        icon: 'warning',
        showCancelButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          let geofence_id = zone_id;
          let url = `${Config.apiUrl}`;
          if (is_personnel) {
            url = url + `internal_personnel/remove_multiple_personnel_zone_access?apikey=${Config.apiKey}&exceptions=${exceptions_string}&geofence_id=${geofence_id}`
          } else {
            url = url + `internal_machines/remove_multiple_machines_zone_access?apikey=${Config.apiKey}&exceptions=${exceptions_string}&geofence_id=${geofence_id}`
          }
          $.ajax({
            type: 'DELETE',
            url: url,
            dataType: "json",
            success: function () {
              Swal.fire("Done!", "Exceptions Removed!", "success").then((result) => {
                exceptions.forEach(function(exception) {
                  $(".tr-" + exception.replace(/\s/g,'')).remove();
                });

                if ($(".personnel-description-td").length == 0) {
                  let exceptionsContainer = document.getElementById('exceptions-container');
                  exceptionsContainer.style.display = "none";
                  $("#no-exceptions-box").show();
                }
              })
            }
          })
        }
      })
    }
  })

  remove_div.onclick = function () {
    Swal.fire({
      title: `Remove ${is_personnel ? 'personnel' : 'machine'} from the zone`,
      text: `Are you sure you want to remove ${exception_name} from ${zone_name}?`,
      icon: 'warning',
      showCancelButton: true
    }).then((result) => {
      if(result.isConfirmed) {
        let geofence_id = zone_id;
        let url = `${Config.apiUrl}`;
        if (is_personnel) {
          url = url + `internal_personnel/remove_personnel_zone_access?apikey=${Config.apiKey}&name=${exception_name}&geofence_id=${geofence_id}`
        } else {
          url = url + `internal_machines/remove_machines_zone_access?apikey=${Config.apiKey}&name=${exception_name}&geofence_id=${geofence_id}`
        }
        $.ajax({
          type: 'DELETE',
          url: url,
          dataType: "json",
          success: function () {
            Swal.fire("Done!", "Exception Removed!", "success").then((result) => {
                  tr.remove();
                  if ($(".personnel-description-td").length == 0) {
                    let exceptionsContainer = document.getElementById('exceptions-container');
                    exceptionsContainer.style.display = "none";
                    $("#no-exceptions-box").show();
                  }
                }
            )
          },
          error: function () {
            Swal.fire("There was a problem removing the selected personnel from the zone")
          }
        })
      } else {
        Swal.fire('Personnel not removed.')
      }
    })
  }

  tr.appendChild(checkbox_td);
  tr.appendChild(description_td);
  tr.appendChild(access_td);
  tr.appendChild(remove_td);
  exceptionsList.appendChild(tr);
  $("#no-exceptions-box").hide();
}

function addGeofenceToMap(map, item, isPreview, isPerimeterOnMap, isPlanning){
  // Stop mapbox error where it fails to render a source name which has already been rendered
  if (map.getLayer(item.name)) {
    map.removeLayer(item.name);
  }
  if (map.getLayer(item.name + "_outline")) {
    map.removeLayer(item.name + "_outline");
  }
  if (map.getSource(item.name)) {
    map.removeSource(item.name);
  }
  map.addSource(item.name, {
    'type': 'geojson',
    'data': {
      'type': 'Feature',
      'geometry': {
        'type': 'Polygon',
        'coordinates': [
          JSON.parse(item.geofence_array)
        ]
      }
    }
  });
  // when the fence is just the perimeter on the map, we want it to be low opacity
  if (isPerimeterOnMap == true || isPerimeterOnMap == "true"){
    map.addLayer({
      'id': item.name,
      'type': 'fill',
      'source': item.name,
      'layout': {},
      'paint': {
        'fill-color': "#192D3C",
        'fill-opacity': 0.1
      }
    });
    // Add an outline to make it easier to distinguish each zone
    map.addLayer({
      'id': item.name + '_outline',
      'type': 'line',
      'source': item.name,
      'layout': {},
      'paint': {
        'line-color': '#000',
        'line-width': 3
      }
    });
  }else{
    var color = getGeofenceColor(item, false);
    map.addLayer({
      'id': item.name,
      'type': 'fill',
      'source': item.name,
      'layout': {},
      'paint': {
        'fill-color': color,
        'fill-opacity': 0.8
      }
    });
    // Add an outline to make it easier to distinguish each zone
    color = getGeofenceColor(item, true);
    map.addLayer({
      'id': item.name + '_outline',
      'type': 'line',
      'source': item.name,
      'layout': {},
      'paint': {
        'line-color': color,
        'line-width': 1
      }
    });
  }

  if (isPlanning){
    map.on('click', item.name, function (e) {
      resetLayerLines();
      removeResourceRows();
      document.getElementById('timeline').setAttribute('style', 'display:none !important');
      document.getElementById('popout-line-offset-container').setAttribute('style', 'display:none !important');
      document.querySelector('.gantt-container').setAttribute('style', 'display:none !important');
      document.getElementById('gantt-menu-bar').setAttribute('style', 'display:none !important');
      document.getElementById('shrink-gantt').setAttribute('style', 'opacity:1 !important');
      let popoutMenu = document.getElementById('planning-popout-menu');
      if (map.getLayer(item.name + '_outline')) {
        map.setPaintProperty(item.name + '_outline', 'line-width', 5);
      }
      document.getElementById('popout-zone-name').innerText = item.name;
      popoutMenu.classList.remove('animate__slideOutRight');
      popoutMenu.classList.add('animate__animated', 'animate__slideInRight',  'animate__faster');
      popoutMenu.setAttribute('style', 'visibility:visible !important');
      document.getElementById('popout-date-from').value = moment(item.start_date).format('YYYY-MM-DD');
      document.getElementById('popout-date-to').value = moment(item.end_date).format('YYYY-MM-DD');
      document.getElementById('dependent').value = item.depends_on;
      document.getElementById('default_access_level').value = item.planning_access_level;
      let changeLogTable = document.getElementById('popout-change-log-table');
      let newTbody = document.createElement('tbody');
      newTbody.id = 'popout-change-log-table';
      if (item.geofence_change_logs){
        let changeLogs = item.geofence_change_logs;
        for (let i = 0; i < changeLogs.length; i++){
          let tr = document.createElement('tr');
          let previousStartDateTd = document.createElement('td');
          previousStartDateTd.innerText = changeLogs[i].previous_start_date;
          let previousEndDateTd = document.createElement('td');
          previousEndDateTd.innerText = changeLogs[i].previous_end_date;
          let newStartDateTd = document.createElement('td');
          newStartDateTd.innerText = changeLogs[i].new_start_date;
          let newEndDateTd = document.createElement('td');
          newEndDateTd.innerText = changeLogs[i].new_end_date;
          let reasonTd = document.createElement('td');
          reasonTd.innerText = changeLogs[i].reason;
          let timestampTd = document.createElement('td');
          timestampTd.innerText = changeLogs[i].timestamp;
          tr.appendChild(previousStartDateTd);
          tr.appendChild(previousEndDateTd);
          tr.appendChild(newStartDateTd);
          tr.appendChild(newEndDateTd);
          tr.appendChild(reasonTd);
          tr.appendChild(timestampTd);
          newTbody.appendChild(tr);
        }
      }
      changeLogTable.parentNode.replaceChild(newTbody, changeLogTable);
      if (item.is_access_way){
        document.getElementById('popout-line-offset-container').setAttribute('style', 'display:block !important');
        document.getElementById('popout-line-offset').value = item.offset;
      }
      document.getElementById('clicked_geofence').value = item.id;
      document.getElementById('permit_id').value = item.permit_id;
      if (item.permit_resources){
        let resource_data = item.permit_resources;
        resource_data.forEach(resource => createResourceSelects(resource));
      }
      let latitude = item.coordinates.split(", ")[0];
      let longitude = item.coordinates.split(", ")[1];
      map.flyTo({
        center: [
          latitude,
          longitude
        ],
        zoom: 17,
        essential: true // this animation is considered essential with respect to prefers-reduced-motion
      });
    })
  }
  if (isPreview) {
      // Add onClick popup for each zone
      map.on('click', item.name, function (e) {
        var title = '<div class="preview-map-popup">' + item.name +
            '<br>' + 'Version: #' + item.version +
            '<br>' + 'Geofence Start Date: ' + item.start_date + '</div>'
        new mapboxgl.Popup()
            .setLngLat(e.lngLat)
            .setHTML(title)
            .addTo(map);
      });
  }
}

function getGeofenceColor(item, isOutline, isMachineZone){
  if (isOutline && isMachineZone) {
    return '#373737'
  }
  var hazardTagCreationMethod = 3;
  if (item.is_active == false || item.is_active == 'false') {
    return '#373737'
  }
  if (item.creation_method == hazardTagCreationMethod || item.creation_method == "3"){
    if (isOutline == true){
      return '#5b007d'
    }else{
      return '#ba03fc'
    }
  }else if (item.is_access_way != null && (item.is_access_way == "true" || item.is_access_way == true)){
    if (isOutline == true){
      return '#00ff58';
    }else{
      return '#48c774';
    }
  }
  if (item.access_level == 1){
    if (isMachineZone == true) {
      return '#35ff78';
    }
    if (isOutline == true){
      return '#00ff58';
    }else{
      return '#2b8449';
    }
  }else if (item.access_level == 3){
    if (isMachineZone == true) {
      return '#ff0024';
    }
    if(isOutline == true){
      return '#ff0030';
    }else{
      return '#f14668';
    }
  }else {
    return '#2b8449';
  }
}

function addImageToMap(map, item, sourceType, layerType, sourceLayer, lineColor, lineWidth){
  // Stop mapbox error where it fails to render a source name which has already been rendered
  if (map.getLayer(item.file_name)) {
    map.removeLayer(item.file_name);
  }
  if (map.getLayer(item.file_name + "_outline")) {
    map.removeLayer(item.file_name + "_outline");
  }
  if (map.getSource(item.file_name)) {
    map.removeSource(item.file_name);
  }
  let url = `mapbox://${MapConfig.username}.${item.file_name}`;
  map.addSource(`${item.file_name}`, {
    "type": sourceType,
    "url": url
  });
  if(sourceType === "raster"){
    // Check if polygons exist before setting beforeId, if not, check for locations layer
    let beforeId = '';
    if (map.getLayer('polygons')){
      beforeId = 'polygons'
    }else{
      if (map.getLayer('locations')){
        beforeId = 'locations'
      }
    }
    map.addLayer({
      "id": `${item.file_name}-layer`,
      "type": layerType,
      "source": item.file_name,
      "paint": {
        "raster-opacity": 0.7
      }
    }, beforeId);
  }else {
    // Set defaults in case there are geojsons that dont have a color and width attribute
    let color;
    let width;
    if (lineColor == null) {
      color = 'black';
    }else {
      color = lineColor;
    }
    if (lineWidth == null) {
      width = 4;
    }else {
      width = lineWidth;
    }
    map.addLayer({
      "id": `${item.file_name}`,
      "type": layerType,
      "source": `${item.file_name}`,
      "source-layer": sourceLayer,
      'paint': {
        'line-color': color,
        'line-width': width
      }
    });
  }
}

const connectDataAjaxCall = map => {
  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/connect_data?apikey=${Config.apiKey}&site_id=${siteId}`,
    dataType: "json",
    success: data => {
      let connectPoints = []

      data.connect_data.forEach((item, index) => {
        let connect = {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [item.longitude, item.latitude]
          },
          properties: {
            title: item.connect_id,
            connected_devices: item.num_connected_devices,
            external_connection_status: item.external_connection_status
          }
        };
        connectPoints.push(connect);
      });
      const geojson = {
        type: 'FeatureCollection',
        features: connectPoints
      };

      $(".connect-green-marker").remove();
      $(".connect-red-marker").remove();

      geojson.features.forEach(marker => {
        const el = document.createElement('div');
        el.className = marker.properties.external_connection_status === false
            || marker.properties.external_connection_status === "false"
            ? 'connect-red-marker' : 'connect-green-marker';

        const markerOptions = {
          element: el,
          anchor: 'bottom'
        }

        new mapboxgl.Marker(markerOptions)
            .setLngLat(marker.geometry.coordinates)
            .setPopup(
                new mapboxgl.Popup({ offset: 25 }) // add popups
                    .setHTML(
                        `
              <h3>ID: ${marker.properties.title}</h3>
              <p>Connected Devices: ${marker.properties.connected_devices}</p>
              <p>External Connection Status: ${marker.properties.external_connection_status}</p>
            `
                    )
            )
            .addTo(map);
      });
    }
  });
}

const machineTagLocationCallWithDateRange = (map, datePararm, precisionLevel) => {
  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/machine_tag_location_data_with_date?apikey=${Config.apiKey}&site_id=${siteId}&event_type=${eventTypeIds}&date=${datePararm}&precision_level=${precisionLevel}&machine_ids=${machineIds}`,
    dataType: "json",
    success: data => {
      if (data !== undefined) {
        plotMachineTagLocationMarkers(map, data.machine_tag_location_data, true);
      }
    },
    error: data => {
      console.log("Error trying to retrieve machine tag location data. Please refresh the page and try again.");
    }
  });
}

const machineTagLocationDataAjaxCall = (map, siteId, isTunnel, isLive) => {
  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/machine_tag_location_data?apikey=${Config.apiKey}&site_id=${siteId}&event_type=${eventTypeIds}&machines=${machineIds}&is_tunnel=${isTunnel}`,
    dataType: "json",
    success: data => {
      if (data !== undefined) {
        plotMachineTagLocationMarkers(map, data.machine_tag_location_data, false, isLive);
      }
    },
    error: data => {
      console.log("Error trying to retrieve machine tag location data. Please refresh the page and try again.");
    }
  });
}

const plotMachineTagLocationMarkers = (map, data, is_range, isLive) => {
  // remove existing location markers
  if (is_range == true){
    $(".machineLocationMarker").remove();
  } else {
    $(".machineLocationMarker").remove();
    $(".machineLiveLocationMarker").remove();
    $(".live-machine-location-during-zone-interaction-marker").remove();
  }

  // Need to show different types of points if looking at live or historical data
  if (isLive) {
    const geojson = {
      type: 'FeatureCollection',
      features: data
    };

    for (const marker of data) {
      var title = marker.properties.title + '<br>' + marker.properties.description
      title = title + '<br>' + marker.properties.timestamp;
      // Create a DOM element for each marker.
      const el = document.createElement('div');
      if (marker.properties.during_zone_interaction == true) {
        el.className = 'live-machine-location-during-zone-interaction-marker';
      } else {
        el.className = 'machineLiveLocationMarker';
      }
      el.style.textAlign = "center";
      el.style.width = `30px`;
      el.style.height = `30px`;
      let popup = new mapboxgl.Popup({offset: 10, anchor: 'bottom'}).setHTML(title);
      // Add markers to the map.
      new mapboxgl.Marker(el)
          .setLngLat(marker.geometry.coordinates.slice())
          .setPopup(popup)
          .addTo(map);
    }
  } else {
    addLayerToMap(map, 'machine-locations', data, null, true, "#47a3da")
  }
}

// Used by the broken/lost screen to display a site's assigned connects on the map
const connectBrokenLostAjaxCall = (map, siteId) => {
  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}map_sites/get_connect_coordinates?apikey=${Config.apiKey}&site_id=${siteId}`,
    dataType: "json",
    success: data => {
      if (data !== undefined) {
        plotConnectLocationMarkers(map, data);
      }
    },
    error: data => {
      console.log("Error trying to retrieve connect location data. Please refresh the page and try again.");
    }
  });
}

const plotConnectLocationMarkers = (map, data) => {
  let locationPoints = []
  data.coordinates.forEach((item, index) => {
    let location = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [item[1], item[2]],
        options: null
      },
      properties: {
        title: 'Connect Location',
        description: `Connect #${item[0]}`,
      }
    };
    locationPoints.push(location);
  });

  const geojson = {
    type: 'FeatureCollection',
    features: locationPoints
  };
  for (const marker of geojson.features) {
    var title = marker.properties.title + '<br>' + marker.properties.description
    title = title + '<br>' + marker.properties.timestamp;
    // Create a DOM element for each marker.
    const el = document.createElement('div');
    // Set data attribute as the connect's 5 digit id
    el.setAttribute("data", marker.properties.description.substring(marker.properties.description.length - 5))
    el.setAttribute("id", "map-connect-icon")
    el.className = 'connect-green-marker';
    el.style.textAlign = "center";
    el.style.width = `35px`;
    el.style.height = `35px`;
    let popup = new mapboxgl.Popup({offset: 10, anchor: 'bottom'}).setHTML(title);

    // Add markers to the map.
    new mapboxgl.Marker(el)
        .setLngLat(marker.geometry.coordinates.slice())
        .setPopup(popup)
        .addTo(map);

    // Add event listener
    el.addEventListener('click', function() {
      // Put the connect_id of this icon into the text box in the form
      let connectTextBox = document.getElementById('connect_id_text_box');
      connectTextBox.value = marker.properties.description.substring(marker.properties.description.length - 5);
    });
  }
}

const hazardTagAjaxCall = (map, siteId) => {
  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/hazard_tag_location_data?apikey=${Config.apiKey}&site_id=${siteId}`,
    dataType: "json",
    success: data => {
      if (data !== undefined) {
        let locationPoints = []
        data.hazard_tag_data.forEach((item) => {
          let location = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [item.longitude, item.latitude],
              options: null
            },
            properties: {
              title: 'Hazard Tag Location',
              description: `Hazard Tag #${item.hazard_tag_id}`,
              total_tags_in_zone: item.total_tags_in_zone,
              tag_radius: item.ht_zone_distance
            }
          };
          locationPoints.push(location);
        });

        // remove existing location markers
        $(".hazardTagLocationMarker").remove();

        const geojson = {
          type: 'FeatureCollection',
          features: locationPoints
        };
        let count = 0;
        for (const marker of geojson.features) {
          var title = marker.properties.title + '<br>' + marker.properties.description;
          // Create a DOM element for each marker.
          const el = document.createElement('div');
          el.className = 'hazardTagLocationMarker';
          el.style.width = `30px`;
          el.style.height = `30px`;
          let popup = new mapboxgl.Popup({offset: 25, anchor: 'bottom'}).setHTML(title);

          // Add markers to the map.
          new mapboxgl.Marker(el)
              .setLngLat(marker.geometry.coordinates.slice())
              .setPopup(popup)
              .addTo(map);

          // Add an event listener only if this is being made on the broken/lost screen
          if (document.getElementById('product_type')) {
            el.addEventListener('click', function () {
              // Put the hazard_tag_id of this icon into the text box in the form
              let textBox = document.getElementById('hazard_tag_id_text_box');
              textBox.value = marker.properties.description.substring(marker.properties.description.length - 7);
            });
          }

          if (marker.properties.total_tags_in_zone === 1 || marker.properties.total_tags_in_zone === '1'){
            const center = marker.geometry.coordinates;
            const radius = marker.properties.tag_radius / 1000;
            const options = {
              units: "kilometers"
            }
            const circle = turf.circle(center, radius, options);
            map.addSource("hazardTagRadius" + count.toString(), {
              type: "geojson",
              data: circle
            });


            map.addLayer({
              id: "hazard-tag-circle-fill-" + count.toString(),
              type: "fill",
              source: "hazardTagRadius" + count.toString(),
              paint: {
                "fill-color": "red",
                "fill-opacity": 0.2
              }
            });
          }
          count = count + 1;
        }
      }
    }, error: data => {
      console.log("Error trying to retrieve hazard tag data. Please refresh the page and try again.")
    }
  });
}

const peoplePlantInteractionsAjaxCall = (map, date_param, precision_level) => {
  $.ajax ({
    type: 'GET',
    url: `${Config.apiUrl}live/people_plant_interactions_with_date?apikey=${Config.apiKey}&event_type=${eventTypeIds}&site_id=${siteId}&user_ids=${userIds}&date=${date_param}`,
    dataType: "json",
    success: data => {
      if (data && data.data) {
        let workerPoints = [];
        let machinePoints = [];
        let linePoints = [];
        data.data.forEach((item, index) => {
          let worker_location = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [item.worker_longitude, item.worker_latitude],
              options: null
            },
            properties: {
              title: "People Plant Interaction",
              description: `Worker #${item.worker_id} Teamtag #${item.teamtag_id}`,
              timestamp : item.timestamp,
              event_color: get_event_color(item.event_type_id, item,true)
            }
          };
          let machine_location = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [item.machine_longitude, item.machine_latitude],
              options: null
            },
            properties: {
              title: 'People Plant Interaction',
              description: `Machine #${item.machine_id}`,
              timestamp : item.timestamp,
              event_color: get_event_color(item.event_type_id, item,true)
            }
          };
          // Only plot the line if the worker and machine locations are within 50 meters of each other
          let p1 = {
            latitude: item.worker_latitude,
            longitude: item.worker_longitude
          }
          let p2 = {
            latitude: item.machine_latitude,
            longitude: item.machine_longitude
          }
          if (checkIfPointsAreClose(p1, p2)) {
            let line = {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [
                  [item.worker_longitude, item.worker_latitude],
                  [item.machine_longitude, item.machine_latitude]
                ],
                options: null
              }
            };
            linePoints.push(line);
          }
          workerPoints.push(worker_location);
          machinePoints.push(machine_location);
        });
        const geojsonWorkers = {
          type: 'FeatureCollection',
          features: workerPoints
        };
        const geojsonMachines = {
          type: 'FeatureCollection',
          features: machinePoints
        }
        const geojson_lines = {
          type: 'FeatureCollection',
          features: linePoints
        }
        addLineToMap(map, 'people_plant_lines', '#FF0000', geojson_lines);
        addLayerToMap(map, 'people_plant_workers',  geojsonWorkers, eventTypeIds, false, '#FFA500');
        addLayerToMap(map, 'people_plant_machines', geojsonMachines, eventTypeIds, false, '#001751');
      }
    }
  });
}

// Checks to see if two points are within 50 meters of eachother
function checkIfPointsAreClose(p1, p2) {
  // https://www.movable-type.co.uk/scripts/latlong.html
  const R = 6371e3; // metres
  const φ1 = p1.latitude * Math.PI/180; // φ, λ in radians
  const φ2 = p2.latitude * Math.PI/180;
  const Δφ = (p2.latitude - p1.latitude) * Math.PI/180;
  const Δλ = (p2.longitude - p1.longitude) * Math.PI/180;
  const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
      Math.cos(φ1) * Math.cos(φ2) *
      Math.sin(Δλ/2) * Math.sin(Δλ/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  let d = R * c; // in metres
  return d < 50;
}

const sectorAjaxCall = (map, sector_id, is_productivity) => {
  if (!is_productivity) {
    is_productivity = false
  }
  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/geofence_data_for_sector?apikey=${Config.apiKey}&sector_id=${sector_id}&is_productivity=${is_productivity}`,
    dataType: "json",
    success: data => {
      if (data !== null && data.geofence_data !== null) {
        let geofence_collection = [];
        // foreach geofence create a layer on the map with an outline
        data.geofence_data.forEach((item, index) => {
          if (item.geofence_array) {
            let fillColor = getGeofenceColor(item, false);
            let polygon = {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [JSON.parse(item.geofence_array)]
              },
              properties: {
                title: item.name,
                start_date: item.start_date,
                version: item.version,
                zone_id: item.zone_id,
                zone_name: item.name,
                fill_color: fillColor
              }
            };
            geofence_collection.push(polygon);
          }
        });
        const geojson = {
          type: 'FeatureCollection',
          features: geofence_collection
        };
        map.addSource('sectors_source', {
          type: 'geojson',
          data: geojson
        })
        let beforeId = '';
        if (map.getLayer('clusters')) {
          beforeId = 'clusters'
        }
        map.addLayer({
          id: 'sectors',
          type: 'fill',
          source: 'sectors_source',
          paint: {
            'fill-color': ['get', 'fill_color'],
            'fill-opacity': 0.6,
          }
        }, beforeId);
      }
      // Also get any sector images
      imageDataAjaxCall(map, null, null, null, null, null, null, null, null, null, sector_id, false, true, false)
    }, error: data => {
      console.log("Error trying to load geofence data. Please refresh the page and try again.");
    }
  });
}

const tunnelTagChainAjaxCall = (map, tunnelTagControlId, tunnelId, isUpdate, isPreview, showCircles) => {
  let url = `${Config.apiUrl}live/tunnel_tag_chain_data?apikey=${Config.apiKey}&tunnel_tag_control_id=${tunnelTagControlId}`
  if (tunnelId) {
    url = `${Config.apiUrl}live/tunnel_tag_chain_data?apikey=${Config.apiKey}&tunnel_tag_control_id=${tunnelTagControlId}&tunnel_id=${tunnelId}`
  }
  $.ajax ({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: data => {
        if (data.tunnel_tag_data != null) {
          let points = [];
          let coords = [];
          data.tunnel_tag_data.forEach((item, index) => {
            if (!isUpdate) {
              // Create the points
              let point = {
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: [item.longitude, item.latitude]
                },
                properties: {
                  'title': `Tunnel Tag ID #${item.tunnel_tag_id}`
                }
              }
              points.push(point);
              coords.push([item.longitude, item.latitude]);
            }
            else {
              // Ignore the last point if we are updating the last TUT
              if (index === data.tunnel_tag_data.length - 1) {
                // Create the points
                let point = {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: [item.longitude, item.latitude]
                  },
                  properties: {
                    'title': `Tunnel Tag ID #${item.tunnel_tag_id}`
                  }
                }
                points.push(point);
                coords.push([item.longitude, item.latitude]);
              }
            }
          });
          // Create circles around all tunnel tags but the last one, to indicate where a new tunnel tag can't be placed
          if (showCircles) {
            coords.forEach((item, index) => {
              let name = "circle_" + index;
              // An accurate plot is only needed for the last circle (note the points param in createJSONCircle)
              if (index === coords.length - 1) {
                // Distance between points = (diameter * pi) / number of points
                map.addSource(name, createJSONCircle([item[0], item[1]], 25.0, 8192));
                let colour;
                if (isPreview) {
                  colour = '#4192ff'
                } else {
                  colour = 'blue'
                }
                map.addLayer({
                  'id': name,
                  'type': 'fill',
                  'source': name,
                  'layout': {},
                  'paint': {
                    'fill-color': colour,
                    'fill-opacity': 0.2
                  }
                });
              }
              else {
                map.addSource(name, createJSONCircle([item[0], item[1]], 25.0));
                let colour;
                if (isPreview) {
                  colour = '#4192ff'
                } else {
                  colour = 'red'
                }
                map.addLayer({
                  'id': name,
                  'type': 'fill',
                  'source': name,
                  'layout': {},
                  'paint': {
                    'fill-color': colour,
                    'fill-opacity': 0.2
                  }
                });
              }
            });
          }
          const geojson = {
            type: 'FeatureCollection',
            features: points
          };
          for (const marker of geojson.features) {
            var title = marker.properties.title;
            // Create a DOM element for each marker.
            const el = document.createElement('div');
            el.className = 'tunnelTagLocationMarker';
            el.style.width = `30px`;
            el.style.height = `30px`;
            let popup = new mapboxgl.Popup({offset: 25, anchor: 'bottom'}).setHTML(title);

            // Add markers to the map.
            new mapboxgl.Marker(el)
                .setLngLat(marker.geometry.coordinates.slice())
                .setPopup(popup)
                .addTo(map);
          }
          let pointsSourceName = 'tunnel_tag_points_source' + tunnelTagControlId;
          map.addSource(pointsSourceName, {
            type: 'geojson',
            data: geojson
          });
          let linesSourceName = 'tunnel_tag_lines_source' + tunnelTagControlId;
          map.addSource(linesSourceName, {
            type: 'geojson',
            data: {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: coords
              }
            }
          });
          let pointsId = 'tunnel_tags_points' + tunnelTagControlId;
          map.loadImage('../assets/broadcast-signal.png', (error, image) => {
            if (error) throw error;
            map.addImage('tut-image', image, {'sdf': false});
            map.addLayer({
              'id': pointsId,
              'type': 'symbol',
              'source': pointsSourceName,
              'layout': {
                'icon-image': 'tut-image',
                "icon-size": 0.06
              },
            });
          });
        }
        if (data.tunnel_tag_control_data != null && data.tunnel_tag_control_data !== [] && data.tunnel_tag_control_data.length !== 0) {
          // We only receive one TUTC, so no need to iterate here
          let point = [{
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [data.tunnel_tag_control_data[0].longitude, data.tunnel_tag_control_data[0].latitude]
            },
            properties: {
              'title': `Tunnel Tag Control ID #${tunnelTagControlId}`
            }
          }];
          const geojson = {
            type: 'FeatureCollection',
            features: point
          };
          $(".connect-green-marker").remove();
          $(".connect-red-marker").remove();

          geojson.features.forEach(marker => {
            const el = document.createElement('div');
            el.className = 'connect-green-marker';

            const markerOptions = {
              element: el,
              anchor: 'bottom'
            }

            new mapboxgl.Marker(markerOptions)
                .setLngLat(marker.geometry.coordinates)
                .setPopup(
                    new mapboxgl.Popup({ offset: 25 }) // add popups
                        .setHTML(
                            `
              <h3>Tunnel Tag Control ID: ${tunnelTagControlId}</h3>
            `                   )
                )
                .addTo(map);
          });
        }
      }
    });
}

// Retrieves the second-to-last tunnel tag in a chain. Useful for when plotting a tunnel tag
const secondToLastTunnelTagAjaxCall = (map, tunnelTagControlId, tunnelId) => {
  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/second_to_last_tunnel_tag?apikey=${Config.apiKey}&tunnel_tag_control_id=${tunnelTagControlId}&tunnel_id=${tunnelId}`,
    dataType: "json",
    success: data => {
      if (data.tunnel_tag_data != null) {
        let points = [];
        let coords = [];
        data.tunnel_tag_data.forEach((item, index) => {
          // Create the points
          let point = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [item.longitude, item.latitude]
            },
            properties: {
              'title': `Tunnel Tag ID #${item.tunnel_tag_id}`
            }
          }
          points.push(point);
          coords.push([item.longitude, item.latitude]);
          coords.forEach((item, index) => {
            let name = "circle_" + index;
            // An accurate plot is only needed for the last circle (note the points param in createJSONCircle)
            // Distance between points = (diameter * pi) / number of points
            map.addSource(name, createJSONCircle([item[0], item[1]], 25.0, 8192));
            let colour = 'blue';
            map.addLayer({
              'id': name,
              'type': 'fill',
              'source': name,
              'layout': {},
              'paint': {
                'fill-color': colour,
                'fill-opacity': 0.2
              }
            });
          });
        });
        const geojson = {
          type: 'FeatureCollection',
          features: points
        };
        for (const marker of geojson.features) {
          var title = marker.properties.title;
          // Create a DOM element for each marker.
          const el = document.createElement('div');
          el.className = 'tunnelTagLocationMarker';
          el.style.width = `30px`;
          el.style.height = `30px`;
          let popup = new mapboxgl.Popup({offset: 25, anchor: 'bottom'}).setHTML(title);

          // Add markers to the map.
          new mapboxgl.Marker(el)
              .setLngLat(marker.geometry.coordinates.slice())
              .setPopup(popup)
              .addTo(map);
        }
        let pointsSourceName = 'tunnel_tag_points_source' + tunnelTagControlId;
        map.addSource(pointsSourceName, {
          type: 'geojson',
          data: geojson
        });
        let linesSourceName = 'tunnel_tag_lines_source' + tunnelTagControlId;
        map.addSource(linesSourceName, {
          type: 'geojson',
          data: {
            type: 'Feature',
            geometry: {
              type: 'LineString',
              coordinates: coords
            }
          }
        });
        let pointsId = 'tunnel_tags_points' + tunnelTagControlId;
        map.loadImage('../assets/broadcast-signal.png', (error, image) => {
          if (error) throw error;
          map.addImage('tut-image', image, {'sdf': false});
          map.addLayer({
            'id': pointsId,
            'type': 'symbol',
            'source': pointsSourceName,
            'layout': {
              'icon-image': 'tut-image',
              "icon-size": 0.06
            },
          });
        });
      }
    }
  });
}

const tunnelAjaxCall = (map, tunnelId) => {
  // Determine whether we are requesting all tunnels on site, or just one tunnel
  let url = null;
  if (tunnelId) {
    url = `${Config.apiUrl}live/tunnel_preview_data?apikey=${Config.apiKey}&tunnel_id=${tunnelId}`
  } else {
    url = `${Config.apiUrl}live/tunnel_preview_data?apikey=${Config.apiKey}&site_id=${siteId}`
  }
  $.ajax({
    type: 'GET',
    url: url,
    dataType: "json",
    success: data => {
      if (data.tunnel_data != null) {
        let tunnels = [];
        // foreach tunnel create a layer on the map with an outline
        data.tunnel_data.forEach((item, index) => {
          if (item.geofence_array) {
            let polygon = {
              type: 'Feature',
              geometry: {
                type: 'Polygon',
                coordinates: [JSON.parse(item.geofence_array)]
              },
              properties: {
                title: item.name
              }
            };
            tunnels.push(polygon);
          }
        });
        const geojson = {
          type: 'FeatureCollection',
          features: tunnels
        };
        // Need to try and remove the previous source, as we can't have two sources with the same id
        try {
          map.removeSource('tunnels_source');
        } catch (err) {}
        map.addSource('tunnels_source', {
          type: 'geojson',
          data: geojson
        });
        let beforeId = '';
        if (map.getLayer('clusters')) {
          beforeId = 'clusters'
        }
        map.addLayer({
          id: 'tunnels',
          type: 'fill',
          source: 'tunnels_source',
          paint: {
            'fill-color': '#C4C4C4',
            'fill-opacity': 0.2,
          }
        }, beforeId);
      }
      }, error: data => {
      console.log("Error trying to load tunnel data. Please refresh the page and try again.");
    }
  });
}

function addLayerToMap(map, layerId, data, event_ids, should_cluster, colorCode){
  if(!should_cluster){
    map.addLayer({
      id: layerId,
      type: 'circle',
      source: {
        type: 'geojson',
        data: data
      },
      paint: {
        'circle-radius': 8,
        'circle-color': colorCode ? colorCode : ['get', 'event_color'],
        'circle-opacity': 0.8
      }
    });
  }else{
    // Need to try and remove the previous source, as we can't have two sources with the same id
    try {
      map.eachSource(function (source) {
        map.removeSource(source);
      });
    } catch (err) {}

    let sourceName = 'location-source-' + layerId
    let clusterName = 'clusters-' + layerId
    map.addSource(sourceName, {
      type: 'geojson',
      data: {
        "type": "FeatureCollection",
        "features": data
      },
      cluster: true,
      clusterMaxZoom: 14, // Max zoom to cluster points on
      clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
    });

    let clusterColor;
    if(event_ids && event_ids.split(" ").length == 1){
      clusterColor = get_event_color(event_ids, null,true)
    }else{
      clusterColor = '#3990ff'
    }

    map.addLayer({
      id: clusterName,
      type: 'circle',
      source: sourceName,
      filter: ['has', 'point_count'],
      paint: {
        // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
        // with three steps to implement three types of circles:
        'circle-color': clusterColor,
        'circle-radius': [
          'step',
          ['get', 'point_count'],
          20,
          50,
          30,
          250,
          40
        ],
        'circle-opacity': 1
      }
    });
    map.addLayer({
      id: 'cluster-count' + layerId,
      type: 'symbol',
      source: sourceName,
      filter: ['has', 'point_count'],
      layout: {
        'text-field': '{point_count_abbreviated}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      },
      paint: {
        "text-color": "#ffffff"
      }
    });
    map.addLayer({
      id: layerId,
      type: 'circle',
      source: sourceName,
      filter: ['!', ['has', 'point_count']],
      paint: {
        'circle-radius': 8,
        'circle-color': colorCode ? colorCode : ['get', 'event_color'],
        'circle-opacity': 1
      }
    });

    map.on('click', clusterName, (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: [clusterName]
      });
      const clusterId = features[0].properties.cluster_id;
      map.getSource(sourceName).getClusterExpansionZoom(
          clusterId,
          (err, zoom) => {
            if (err) return;

            map.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom
            });
          }
      );
    });
  }

  map.on('click', layerId, function (e) {
    var coordinates = e.features[0].geometry.coordinates.slice();
    var title = e.features[0].properties.title + '<br>' + e.features[0].properties.description
    title = title + '<br>' + e.features[0].properties.timestamp;

    // Ensure that if the map is zoomed out such that multiple
    // copies of the feature are visible, the popup appears
    // over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }

    new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(title)
        .addTo(map);
  });

  // Change the cursor to a pointer when the mouse is over the states layer.
  map.on('mouseenter', layerId, function () {
    map.getCanvas().style.cursor = 'pointer';
  });

  // Change it back to a pointer when it leaves.
  map.on('mouseleave', layerId, function () {
    map.getCanvas().style.cursor = '';
  });

  $(document.body).css({'cursor' : 'default'});
}

function addLineToMap(map, layerId, colorCode, data) {
  map.addLayer({
    id: layerId,
    type: 'line',
    source: {
      type: 'geojson',
      data: data
    },
    paint: {
      'line-width': 3,
      'line-color': colorCode,
      'line-opacity': 0.8
    }
  });
}

function generateRandomColor(){
  return "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);});

}

const set_description = (item) => {
  // Set ids in description bubble
  if (item.event_type_id === EVENT_IDS.BUMP_START_ID) {
    return `Users: #${item.worker_id} & #${item.collision_worker_id}`;
  } else if ((item.event_type_id === EVENT_IDS.MACHINE_TAG_ZONE_ENTRY_ID || item.event_type_id === EVENT_IDS.MACHINE_TAG_LOCATION_ID || item.event_type_id === EVENT_IDS.MACHINE_SPEEDING_START_ID || item.event_type_id === EVENT_IDS.MACHINE_TAG_IDLE_START) && item.is_machine) {
    return `Machine #${item.fleet_number}`;
  } else {
    let string = `User #${item.worker_id}`;
    if (item.role && item.role != ''){
      string += `\nRole: ${item.role}`
    }
    return string
  }
}

const plant_plan_interactions = (item) => {
  let joinedPoints1 = [];
  let joinedPoints2 = [];
  let linePoints = [];

  let from_location = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [item.start_from_longitude, item.start_from_latitude],
      options: null
    },
    properties: {
      title: "Plant Plant Interaction",
      description: `Machine #${item.from_device_id}`,
      timestamp : item.timestamp,
      event_color: get_event_color(item.event_type_id, item, false)
    }
  };
  joinedPoints1.push(from_location);
  let coll_location = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [item.start_collision_longitude, item.start_collision_latitude],
      options: null
    },
    properties: {
      title: 'Plant Plant Interaction',
      description: `Machine #${item.collision_device_id}`,
      timestamp : item.timestamp,
      event_color: get_event_color(item.event_type_id, item,true)
    }
  };
  joinedPoints2.push(coll_location);
  // Only plot the line if the worker and machine locations are within 50 meters of each other
  let p1 = {
    latitude: item.start_from_latitude,
    longitude: item.start_from_longitude
  }
  let p2 = {
    latitude: item.start_collision_latitude,
    longitude: item.start_collision_longitude
  }
  if (checkIfPointsAreClose(p1, p2)) {
    let line = {
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [item.start_from_longitude, item.start_from_latitude],
          [item.start_collision_longitude, item.start_collision_latitude]
        ],
        options: null
      }
    };
    linePoints.push(line);
  }

  return {
    joinedPoints1: joinedPoints1,
    joinedPoints2: joinedPoints2,
    linePoints: linePoints
  }
}

// Gets bounds from the db and fits the user's view to them
const fitMapToBounds = (map, siteId, siteCoordinates) => {
  $.ajax({
    type: 'GET',
    url: `${Config.apiUrl}live/site_bounds?apikey=${Config.apiKey}&site_id=${siteId}`,
    dataType: "json",
    success: data => {
      // If there are no zones at this site...
      if (!data.bounds) {
        // Zoom into an arbitrary area around the centre of the site
        let NW = [];
        let SE = [];
        // Sometimes the coordinates can be passed as a string (e.g. "12.87556 56.98765")
        if (typeof siteCoordinates === 'string' || siteCoordinates instanceof String) {
          siteCoordinates = siteCoordinates.split(" ");
        }
        NW = [parseFloat(siteCoordinates[0]) + 0.01, parseFloat(siteCoordinates[1]) - 0.01];
        SE = [parseFloat(siteCoordinates[0]) - 0.01, parseFloat(siteCoordinates[1]) + 0.01];
        map.fitBounds([NW, SE]);
      }
      // Otherwise, this site has zones...
      else {
        // Add some extra space to the bounds, so it fits the screen nicer
        let NW = data.bounds[0];
        let SE = data.bounds[1];
        NW[0] = NW[0] + 0.002
        NW[1] = NW[1] - 0.002
        SE[0] = SE[0] - 0.002
        SE[1] = SE[1] + 0.002
        map.fitBounds([NW, SE]);
      }
    }
  });
}

// From https://stackoverflow.com/a/39006388
var createJSONCircle = function(center, radiusInMeters, points) {
  if (!points) {
    points = 64;
  }
  var coords = {
    latitude: center[1],
    longitude: center[0]
  }
  let km = radiusInMeters / 1000;
  let ret = [];
  let distanceX = km/(111.320*Math.cos(coords.latitude*Math.PI/180));
  let distanceY = km/110.574;
  let theta, x, y;
  for(let i=0; i<points; i++) {
    theta = (i/points)*(2*Math.PI);
    x = distanceX*Math.cos(theta);
    y = distanceY*Math.sin(theta);
    ret.push([coords.longitude+x, coords.latitude+y]);
  }
  ret.push(ret[0]);
  $('#tunnel_tag_circle').val([ret]);
  return {
    "type": "geojson",
    "data": {
      "type": "FeatureCollection",
      "features": [{
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [ret]
        }
      }]
    }
  };
}

export { locationDataAjaxCall, imageDataAjaxCall, geofenceAjaxCall, machineZoneAjaxCall, connectDataAjaxCall, perimeterAjaxCall, machineTagLocationDataAjaxCall, machineTagLocationCallWithDateRange, hazardTagAjaxCall, connectBrokenLostAjaxCall,
peoplePlantInteractionsAjaxCall, fitMapToBounds, sectorAjaxCall, tunnelAjaxCall, tunnelTagChainAjaxCall, secondToLastTunnelTagAjaxCall, showAllMarkersOnMapInRange, createExceptionRow};
